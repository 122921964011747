export const educationData = {
    educationLabel: "Ausbildung",
    education: [
        {
            id: 1,
            institution: 'FEEIT, Skopje, Mazedonien',
            course: 'BSc. Computertechnologien und Ingenieurwissenschaften',
            startYear: '2012',
            endYear: '2016'
        },
    ]
}