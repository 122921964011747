import React, {useContext} from 'react';

import './Skills.css'

import {ThemeContext} from '../../contexts/ThemeContext';
import {all} from '../../data/de/allData'

import SkillsPrintRow from './SkillsPrintRow';

function chunkArray(array, chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
}

function SkillsPrint() {

    const {theme} = useContext(ThemeContext);
    const {skillsData} = all
    const skills = skillsData.skills

    const skillsChunks = chunkArray(skills, 5)

    return (
        <div className="skills--print pagebreak" style={{backgroundColor: theme.secondary}}>
            <div className="skills--printHeader">
                <h2 style={{color: theme.primary}}>{skillsData.skillsLabel}</h2>
            </div>
            <div className="skills--printContainer">
                <div className="skill-print--scroll">
                    {skillsChunks.map(chunk => (
                        <SkillsPrintRow skills={chunk}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SkillsPrint
