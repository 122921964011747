import one from '../../assets/svg/projects/one.svg'
import two from '../../assets/svg/projects/two.svg'
import three from '../../assets/svg/projects/three.svg'
import four from '../../assets/svg/projects/four.svg'
import five from '../../assets/svg/projects/five.svg'
import six from '../../assets/svg/projects/six.svg'
import seven from '../../assets/svg/projects/seven.svg'
import eight from '../../assets/svg/projects/eight.svg'


export const projectsData = {
    visible: 6,
    projectDescriptionLabel: "Projektbeschreibung",
    projectCoreSkillsLabel: "Verwendete Technologien",
    projectsLabel: "Projekte",
    viewAllLabel: "Alle",
    searchProjectsLabel: "Projekt suchen...",
    projects: [
        {
            id: 1,
            projectName: 'Data Cleansing & Migration - ETL',
            projectDesc: 'ETL-Pipelines werden verwendet, um Daten von mehreren alten Legacy-Systemen in ein Cloud-Datenlager zu migrieren.',
            tags: ['Java', 'Apache Beam', 'Google Cloud Platform'],
            code: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/data-pipelines/',
            // demo: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/data-pipelines/',
            image: seven
        },
        {
            id: 2,
            projectName: 'Employee Management System - EMS',
            projectDesc: 'Web-Lösung zur Verwaltung von Verträgen, Zertifikaten, Fachkenntnissen und Lebensläufen der Mitarbeiter innerhalb einer Organisation.',
            tags: ['React.js', 'Java', 'Redis', 'RabbitMQ'],
            code: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/ems/',
            // demo: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/ems/',
            image: three
        },
        {
            id: 3,
            projectName: 'Real Estate Project Investments - RELTP',
            projectDesc: 'Web-Lösung zur Planung und Verwaltung von Investitionsprojekten im Immobiliensektor.',
            tags: ['React.js', 'Java', 'Spring Framework', 'Google Cloud Platform'],
            code: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/reltp/',
            // demo: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/reltp/',
            image: one
        },
        {
            id: 4,
            projectName: 'Generative 3D data',
            projectDesc: 'Ein regelbasiertes Tool, das verwendet wird, um intelligente 3D-Daten mit präzisen Berechnungen zu generieren, die die digitale Version eines physischen Produkts repräsentieren, das produziert wird.',
            tags: ['Kotlin', 'Spring', 'Drools', 'OptaPlanner'],
            code: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/generative-3d-data/',
            // demo: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/generative-3d-data/',
            image: two
        },
        {
            id: 5,
            projectName: 'Claims Management', // QC
            projectDesc: 'Web-Lösung zur Verwaltung von Versicherungsfällen und Schätzung der endgültigen Kosten durch Bereitstellung verschiedener Berechnungen unter Berücksichtigung unterschiedlicher Faktoren.',
            tags: ['Backbone.js', 'Java', 'Spring Framework'],
            code: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/claims/',
            // demo: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/claims/',
            image: eight
        },
        {
            id: 6,
            projectName: 'Damage Capturing Tool', // QT
            projectDesc: 'Die Web-Lösung wird wiederverwendet, um eine Hybridanwendung (Tablet-First) zu erstellen, mit nativen Plugins verfügbar für Android, iOS, Windows 7 und Windows 10+.',
            tags: ['Backbone.js', 'Java', 'Spring Framework'],
            code: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/dct/',
            // demo: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/dct/',
            image: six
        },
        {
            id: 7,
            projectName: 'Offline Damage Capturing', // AM
            projectDesc: 'Hybride Tablet-Anwendung für Mobilgeräte, die verwendet wird, um Fahrzeugschäden zu erfassen und Versicherungsansprüche für die Schäden zu erstellen. Die Anwendung verfügt über sowohl Online- als auch Offline-Fähigkeiten.',
            tags: ['JavaScript', 'Java', 'Spring Framework', 'Titanium Appcelerator'],
            code: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/odc/',
            //demo: 'https://gitfront.io/r/andovaspasija/DPDw39vZeXk8/portoflio-projects-code-samples/tree/odc/',
            image: five
        },
        {
            id: 8,
            projectName: 'Photo Synchronizer',
            projectDesc: 'Mobile hybride Anwendung, die verwendet wird, um Bilder von verschiedenen Geräten zu synchronisieren und sie in der Cloud zu speichern.',
            tags: ['JavaScript', 'Java', 'Spring Framework', 'Titanium Appcelerator'],
            code: '',
            // demo: '',
            image: four
        },
    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/