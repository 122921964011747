import {aboutData} from "./aboutData"
import {achievementData} from "./achievementData"
import {blogData} from "./blogData"
import {contactsData} from "./contactsData"
import {educationData} from "./educationData"
import {experienceData} from "./experienceData"
import {headerData} from "./headerData"
import {projectsData} from "./projectsData"
import {servicesData} from "./servicesData"
import {skillsData} from "./skillsData"
import {socialsData} from "./socialsData"
import {testimonialsData} from "./testimonialsData"
import {navBarData} from "./navBarData"

export const all = {
    aboutData: aboutData,
    achievementData: achievementData,
    blogData: blogData,
    contactsData: contactsData,
    educationData: educationData,
    experienceData: experienceData,
    headerData: headerData,
    projectsData: projectsData,
    servicesData: servicesData,
    skillsData: skillsData,
    socialsData: socialsData,
    testimonialsData: testimonialsData,
    navBarData: navBarData
}