import SpasijaAndovaCV from '../de/SpasijaAndovaCV.pdf'

export const headerData = {
    name: 'Spasija Andova',
    title: "Senior-Softwareingenieur",
    desciption: "Daten sind das neue Öl, aber sie sind auch die neue Haftung. Schützen Sie sie, als hinge Ihr Geschäft davon ab, denn das tut es. - Unbekannt",
    image: '../../../src/assets/jpg/spasjia-selfie.png',
    resumePdf: SpasijaAndovaCV,
    resumeTitle: "SpasijaAndovaCV",
    downloadCVLabel: "Lebenslauf",
    contactLabel: "Kontakt"
}
