import React, {useContext, useState} from 'react';
import {NavHashLink as NavLink} from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
import {IoMenuSharp, IoHomeSharp} from 'react-icons/io5';
import {HiDocumentText} from 'react-icons/hi';
import {BsFillGearFill} from 'react-icons/bs';
import {MdPhone} from 'react-icons/md';
import {FaUser} from 'react-icons/fa';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';

import './Navbar.css';
import {all} from '../../data/de/allData'
import {ThemeContext} from '../../contexts/ThemeContext';

function Navbar() {
    const {theme, setHandleDrawer} = useContext(ThemeContext);
    const {headerData, navBarData} = all


    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
        setHandleDrawer();
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setHandleDrawer();
    };

    const useStyles = makeStyles((t) => ({
        navMenu: {
            fontSize: '2.5rem',
            color: theme.tertiary,
            cursor: 'pointer',
            transform: 'translateY(-10px)',
            transition: 'color 0.3s',
            '&:hover': {
                color: theme.primary,
            },
            [t.breakpoints.down('sm')]: {
                fontSize: '2.5rem',
            },
            [t.breakpoints.down('xs')]: {
                fontSize: '2rem',
            },
        },
        MuiDrawer: {
            padding: '0em 1.8em',
            width: '16em',
            fontFamily: ' var(--primaryFont)',
            fontStyle: ' normal',
            fontWeight: ' normal',
            fontSize: ' 24px',
            background: theme.secondary,
            overflow: 'hidden',
            borderTopRightRadius: '40px',
            borderBottomRightRadius: '40px',
            [t.breakpoints.down('sm')]: {
                width: '14em',
            },
        },
        closebtnIcon: {
            fontSize: '2rem',
            fontWeight: 'bold',
            cursor: 'pointer',
            color: theme.primary,
            position: 'absolute',
            right: 40,
            top: 40,
            transition: 'color 0.2s',
            '&:hover': {
                color: theme.tertiary,
            },
            [t.breakpoints.down('sm')]: {
                right: 20,
                top: 20,
            },
        },
        drawerItem: {
            margin: '2rem auto',
            borderRadius: '78.8418px',
            background: theme.secondary,
            color: theme.primary,
            width: '85%',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            padding: '0 30px 0 0',
            boxSizing: 'border-box',
            border: '2px solid',
            borderColor: theme.primary,
            transition: 'background-color 0.2s, color 0.2s',
            '&:hover': {
                background: theme.primary,
                color: theme.secondary,
            },
            [t.breakpoints.down('sm')]: {
                width: '100%',
                padding: '0 25px 0 0',
                height: '55px',
            },
        },
        drawerLinks: {
            fontFamily: 'var(--primaryFont)',
            width: '50%',
            fontSize: '1rem',
            fontWeight: 600,
            [t.breakpoints.down('sm')]: {
                fontSize: '0.925rem',
            },
        },
        drawerIcon: {
            fontSize: '1.6rem',
            [t.breakpoints.down('sm')]: {
                fontSize: '1.385rem',
            },
        },
    }));

    const classes = useStyles();

    // const shortname = (name) => {
    //     if (name.length > 12) {
    //         return name.split(' ')[0];
    //     } else {
    //         return name;
    //     }
    // };

    return (
        <div className='navbar'>
            <div className='navbar--container'>
                <h1 style={{color: theme.secondary}}>
                    {headerData.name}
                </h1>

                <span id="hamburger-menu">
                    <IoMenuSharp
                        className={classes.navMenu}
                        onClick={handleDrawerOpen}
                        aria-label='Menu'
                    />
                </span>
            </div>
            <Drawer
                variant='temporary'
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleDrawerClose();
                    } else if (reason !== 'escapeKeyDown') {
                        handleDrawerClose();
                    }
                }}
                anchor='left'
                open={open}
                classes={{paper: classes.MuiDrawer}}
                className='drawer'
                disableScrollLock={true}
            >
                <div className='div-closebtn'>
                    <CloseIcon
                        onClick={handleDrawerClose}
                        onKeyDown={(e) => {
                            if (e.key === ' ' || e.key === 'Enter') {
                                e.preventDefault();
                                handleDrawerClose();
                            }
                        }}
                        className={classes.closebtnIcon}
                        role='button'
                        tabIndex='0'
                        aria-label='Close'
                    />
                </div>
                <br/>

                <div onClick={handleDrawerClose}>
                    <div className='navLink--container'>
                        <Fade left>
                            <NavLink
                                to='/'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div className={classes.drawerItem}>
                                    <IoHomeSharp
                                        className={classes.drawerIcon}
                                    />
                                    <span className={classes.drawerLinks}>
                                        {navBarData.homeLabel}
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade left>
                            <NavLink
                                to='/#about'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div className={classes.drawerItem}>
                                    <FaUser className={classes.drawerIcon}/>
                                    <span className={classes.drawerLinks}>
                                        {navBarData.aboutLabel}
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade left>
                            <NavLink
                                to='/#projects'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div className={classes.drawerItem}>
                                    <HiDocumentText
                                        className={classes.drawerIcon}
                                    />
                                    <span className={classes.drawerLinks}>
                                        {navBarData.projectsLabel}
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade left>
                            <NavLink
                                to='/#services'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div className={classes.drawerItem}>
                                    <BsFillGearFill
                                        className={classes.drawerIcon}
                                    />
                                    <span className={classes.drawerLinks}>
                                        {navBarData.servicesLabel}
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>

                        {/*<Fade left>*/}
                        {/*    <NavLink*/}
                        {/*        to='/#blog'*/}
                        {/*        smooth={true}*/}
                        {/*        spy='true'*/}
                        {/*        duration={2000}*/}
                        {/*    >*/}
                        {/*        <div className={classes.drawerItem}>*/}
                        {/*            <FaFolderOpen*/}
                        {/*                className={classes.drawerIcon}*/}
                        {/*            />*/}
                        {/*            <span className={classes.drawerLinks}>*/}
                        {/*                Blog*/}
                        {/*            </span>*/}
                        {/*        </div>*/}
                        {/*    </NavLink>*/}
                        {/*</Fade>*/}

                        <Fade left>
                            <NavLink
                                to='/#contacts'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div className={classes.drawerItem}>
                                    <MdPhone className={classes.drawerIcon}/>
                                    <span className={classes.drawerLinks}>
                                        {navBarData.contactLabel}
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

export default Navbar;
