/* eslint-disable */
import {BiShoppingBag, BiPencil, BiData, BiCloud, BiSupport, BiCycling} from "react-icons/bi";
import {BsCodeSlash, BsClipboardData} from "react-icons/bs";
import {AiOutlineMail, AiFillAudio, AiFillSecurityScan} from "react-icons/ai";
import {
    FaInternetExplorer,
    FaChalkboardTeacher,
    FaCameraRetro,
    FaPinterest,
    FaVideo,
    FaTabletAlt,
    FaRegNewspaper
} from "react-icons/fa";
import {
    BluetoothConnected, BugReportSharp,
    Business,
    CastConnected,
    HighQuality,
    Security,
    SystemUpdate,
    Web
} from "@material-ui/icons";
import {GrCloudSoftware, GrHostMaintenance, GrIntegration, GrSystem} from "react-icons/gr";
import {SiEnterprisedb, SiPowerbi} from "react-icons/si";
import {MdApproval, MdArchitecture} from "react-icons/md";
import {TbHttpConnect} from "react-icons/tb";
import {GiCycle, GiMeshNetwork} from "react-icons/gi";
import {CgSupport} from "react-icons/cg";
import {FcApprove} from "react-icons/fc";


export const servicesData = {
    servicesLabel: "Dienstleistungen",
    services: [
        {
            id: 1,
            title: 'Custom Software Development',
            icon: <BsCodeSlash/>
        },
        {
            id: 2,
            title: 'Web Development',
            icon: <Web/>
        },
        {
            id: 3,
            title: 'Hybrid Mobile App Development',
            icon: <FaTabletAlt/>
        },
        {
            id: 4,
            title: 'Enterprise Software Development',
            icon: <Business/>
        },
        {
            id: 5,
            title: 'Software Architecture & Design',
            icon: <MdArchitecture/>
        },
        {
            id: 6,
            title: 'Systems Integrations',
            icon: <GiMeshNetwork/>
        },
        {
            id: 7,
            title: 'Application Maintenance & Support',
            icon: <GiCycle/>
        },
        {
            id: 8,
            title: 'Software Quality Assurance',
            icon: <MdApproval/>
        },
        {
            id: 9,
            title: 'Data Modeling, Analytics & Visualization',
            icon: <BiData/>
        },
    ]
}