import React, {useContext} from 'react';

import './Skills.css'

import {ThemeContext} from '../../contexts/ThemeContext';
import {skillsImage} from '../../utils/skillsImage'

function SkillsPrint({skills}) {

    const {theme} = useContext(ThemeContext);
    const skillBoxStyle = {
        backgroundColor: theme.secondary,
        boxShadow: `0px 0px 30px ${theme.primary30}`
    }

    return (
        <div className="skills--print" style={{backgroundColor: theme.secondary}}>
            <div className="skills--printContainer">
                {skills.map((skill, id) => (
                    <div className="skill--print--box" key={id} style={skillBoxStyle}>
                        <img src={skillsImage(skill)} alt={skill}/>
                        <h3 style={{color: theme.tertiary}}>
                            {skill}
                        </h3>
                    </div>
                ))
                }
            </div>
        </div>
    )
}

export default SkillsPrint
