import React, {useContext} from 'react';
import {ThemeContext} from '../../contexts/ThemeContext';
import {all} from '../../data/de/allData'

import './ProjectsPrint.css'
import SingleProjectPrint from './SingleProjectPrint/SingleProject';

function ProjectsPrint() {
    const {theme} = useContext(ThemeContext);
    const {projectsData} = all
    const projects = projectsData.projects

    return (
        <>
            <div className="projects--print pagebreak" id="projects--print" style={{backgroundColor: theme.secondary}}>
                <div className="projects--print--header">
                    <h1 style={{color: theme.primary}}>{projectsData.projectsLabel}</h1>
                </div>
                <div className="projects--print--body">
                    <div className="projects--print--bodyContainer">
                        {/*Todo: split projects into chunks of n elements*/}
                        {projects.slice(0, 2).map(project => (
                            <SingleProjectPrint
                                theme={theme}
                                key={project.id}
                                id={project.id}
                                name={project.projectName}
                                desc={project.projectDesc}
                                tags={project.tags}
                                code={project.code}
                                demo={project.demo}
                                image={project.image}
                            />
                        ))}
                    </div>
                    <br></br>
                    <br></br>
                    <div className="projects--print--bodyContainer">
                        {projects.slice(2, 4).map(project => (
                            <SingleProjectPrint
                                theme={theme}
                                key={project.id}
                                id={project.id}
                                name={project.projectName}
                                desc={project.projectDesc}
                                tags={project.tags}
                                code={project.code}
                                demo={project.demo}
                                image={project.image}
                            />
                        ))}
                    </div>
                    <br></br>
                    <br></br>
                    <div className="projects--print--bodyContainer">
                        {/*Todo: split projects into chunks of n elements*/}
                        {projects.slice(4, 6).map(project => (
                            <SingleProjectPrint
                                theme={theme}
                                key={project.id}
                                id={project.id}
                                name={project.projectName}
                                desc={project.projectDesc}
                                tags={project.tags}
                                code={project.code}
                                demo={project.demo}
                                image={project.image}
                            />
                        ))}
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="projects--print--bodyContainer">
                        {/*Todo: split projects into chunks of n elements*/}
                        {projects.slice(6, 8).map(project => (
                            <SingleProjectPrint
                                theme={theme}
                                key={project.id}
                                id={project.id}
                                name={project.projectName}
                                desc={project.projectDesc}
                                tags={project.tags}
                                code={project.code}
                                demo={project.demo}
                                image={project.image}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectsPrint
