import React, {useContext} from 'react';

import './About.css';
import {ThemeContext} from '../../contexts/ThemeContext';
import {all} from "../../data/de/allData";


function About() {

    const {theme} = useContext(ThemeContext);
    const aboutData = all.aboutData
    const resumeLines = aboutData.description2.split("|")
    return (
        <div className="about pagebreak" id="about" style={{backgroundColor: theme.secondary}}>
            <div className="line-styling">
                <div className="style-circle" style={{backgroundColor: theme.primary}}></div>
                <div className="style-circle" style={{backgroundColor: theme.primary}}></div>
                <div className="style-line" style={{backgroundColor: theme.primary}}></div>
            </div>
            <div className="about-body">
                <div className="about-description">
                    <h2 style={{color: theme.primary}}>{aboutData.title}</h2>
                    <p style={{color: theme.tertiary80}}>{aboutData.description1}
                        <br/>
                        {resumeLines.map((line, id) => (
                            <p style={{color: theme.tertiary80}}>
                                <br/>
                                {line}
                            </p>
                        ))}
                    </p>
                </div>
                <div className="about-img">
                    <img
                        src={aboutData.image === 1 ? theme.aboutimg1 : theme.aboutimg2}
                        alt=""
                    />
                </div>
            </div>
        </div>

    )
}

export default About
