export const experienceData = {
    experienceLabel: "Erfahrung",
    experience: [
        {
            id: 1,
            company: 'KAIDO Tech',
            jobtitle: 'Freiberuflicher Senior-Softwareingenieurin',
            startYear: '2022',
            endYear: 'Present'
        },
        {
            id: 2,
            company: 'IKEA',
            jobtitle: 'IT-Berater',
            startYear: '2021',
            endYear: '2022'
        },
        {
            id: 3,
            company: 'Solera, Inc',
            jobtitle: 'Externer Software-Berater',
            startYear: '2020',
            endYear: '2021'
        },
        {
            id: 4,
            company: 'Audatex GmbH',
            jobtitle: 'Externer Software-Berater',
            startYear: '2019',
            endYear: '2020'
        },
        {
            id: 5,
            company: 'Qinshift (a.k.a Seavus)',
            jobtitle: 'Senior (Junior/Mittelstufe) Softwareentwickler',
            startYear: '2016',
            endYear: '2019'
        },
    ]
}