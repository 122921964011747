export const contactsData = {
    email: 'andova@kaidotech.de',
    phone: '+49 40 52167737',
    address: 'Trettaustraße 17, 21107 Hamburg ',
    sheetAPI: '',
    contactLabel: "Kontakt",
    nameLabel: "Name",
    emailLabel: "Email",
    messageLabel: "Nachricht",
    nameHintLabel: "Max Mustermann",
    emailHintLabel: "Max@mustermann.com",
    messageHintLabel: "Ihre Nachricht hier..",
    sendLabel: "Schicken",
    messageSentLabel: "Nachricht gesendet !",
    allFieldsErrorLabel: "Füllen Sie alle Felder aus",
    invalidEmailErrorLabel: "Ungültige E-Mail",
    otherErrorLabel: "Die Nachricht kann nicht gesendet werden! Bitte kontaktieren Sie mich direkt per E-Mail oder telefonisch. Danke ! ",
    serviceId: "service_0pq1p2b",
    templateId: "template_fxjy19g",
    publicKey: "KAFD0PcAqCGmqSR1B"
}