import React, {useContext} from 'react';

import './Achievement.css';
import {ThemeContext} from '../../contexts/ThemeContext';
import {all} from '../../data/de/allData'
import AchievementCard from './AchievementCard';

function Achievement() {

    const {theme} = useContext(ThemeContext);
    const achievementData = all.achievementData
    return (
        <>
            {achievementData.achievements.length > 0 && (
                <div className="achievement pagebreak" id="achievement" style={{backgroundColor: theme.secondary}}>

                    <div className="achievement-body">
                        <h1 style={{color: theme.primary}}>{achievementData.achievementsLabel}</h1>
                        {achievementData.bio.split("|").map(line => (
                            <h4 style={{color: theme.tertiary}}>{line}
                                <br/>
                                <br/>
                            </h4>

                        ))}
                    </div>
                    <div className="achievement-cards">
                        {achievementData.achievements.map(achieve => (
                            <AchievementCard
                                key={achieve.id}
                                id={achieve.id}
                                title={achieve.title}
                                details={achieve.details}
                                date={achieve.date}
                                field={achieve.field}
                                image={achieve.image}/>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}

export default Achievement
