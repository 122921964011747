import React from 'react';
import placeholder from '../../../assets/png/placeholder.png';
import './SingleProject.css';
import {projectsData} from "../../../data/de/projectsData";

function SingleProject({id, name, desc, tags, image, theme}) {
    return (
        <div
            key={id}
            className='singleProjectPrint'
            style={{backgroundColor: theme.primary400}}
        >
            <div className='projectPrintContent'>
                <h2
                    id={name.replace(' ', '-').toLowerCase()}
                    style={{color: theme.tertiary}}
                >
                    {name}
                </h2>
                <img src={image ? image : placeholder} alt={name}/>
                <p
                    className='project--print--desc'
                    style={{
                        color: theme.tertiary,
                    }}
                >
                    <b>{projectsData.projectDescriptionLabel}: </b>
                    {desc}
                </p>
                <p
                    className='project--print--lang'
                    style={{
                        color: theme.tertiary,
                    }}
                >
                    <b>{projectsData.projectCoreSkillsLabel}: </b>
                    {<span key="skills">{tags.join(", ")}</span>}
                </p>
            </div>

        </div>
    );
}

export default SingleProject;
