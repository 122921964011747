import React, {useContext} from 'react';

import {ThemeContext} from '../../contexts/ThemeContext';

import {all} from '../../data/de/allData'
import './Services.css'
import SingleService from './SingleService/SingleService';

function Services() {

    const {theme} = useContext(ThemeContext);
    const {servicesData} = all
    return (
        <>
            {servicesData.services.length > 0 && (
                <div className="services pagebreak" id="services" style={{backgroundColor: theme.secondary}}>
                    <div className="services-header">
                        <h1 style={{color: theme.primary}}>{servicesData.servicesLabel}</h1>
                    </div>
                    <div className="services-body">
                        {/*<p style={{color:theme.tertiary80}}>*/}
                        {/*    These are some of the services I offer. Reach out to me if I can help you with any!*/}
                        {/*</p>*/}
                        <br/>
                        <div className="services-bodycontainer">
                            {servicesData.services.map(services => (
                                <SingleService
                                    key={services.id}
                                    id={services.id}
                                    title={services.title}
                                    icon={services.icon}/>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Services
