import React from 'react'
import {Helmet} from 'react-helmet'

import {
    Navbar,
    Footer,
    Landing,
    About,
    Skills,
    SkillsPrint,
    // Testimonials,
    // Blog,
    Education,
    Experience,
    Contacts,
    Projects,
    ProjectsPrint,
    Services,
    Achievement
} from '../../components'
import {headerData} from '../../data/de/headerData'

function Main() {
    return (
        <div>
            <Helmet>
                <title>{headerData.name}</title>
            </Helmet>

            <Navbar/>
            <Landing/>
            <About/>
            <Services/>
            <Skills/>
            <SkillsPrint/>
            <Projects/>
            <ProjectsPrint/>
            <Experience/>
            <Achievement/>
            <Education/>
            {/*<Testimonials/>*/}
            {/*<Blog/>*/}
            <Contacts/>
            <Footer/>
        </div>
    )
}

export default Main
